import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useMocks } from "../../context"

import logo from "./../../images/purchase_guide_logo.png"
import logoMobile from "./../../images/purchase_guide_logo_mobile.png"
import arrow_back from "./../../pictos/back.svg"
import {
  Back,
  Container,
  AgorastoreLogo,
  AgorastoreLogoMobile,
  BackToWebsite,
  Arrow,
} from "./styled"
import { Row } from "../../styled"

const Header = props => {
  const { header } = useMocks()

  return (
    <Container>
      <Link to="/">
        <Row
          onClick={() => {
            typeof window !== "undefined" && window.history.go(-1)
          }}
        >
          <Arrow src={arrow_back} alt="back" />
          <Back>{header.back}</Back>
        </Row>
      </Link>

      <AgorastoreLogo src={logo} alt="Purchase Guide Logo" />
      <AgorastoreLogoMobile src={logoMobile} alt="Purchase Guide Logo" />
      <div>
        <a
          href="https://www.agorastore.fr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BackToWebsite categories={props.categories}>
            {header.back_to_agorastore}
          </BackToWebsite>
        </a>
      </div>
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
